@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.layoutSection {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.firstBlock {
  @include tablet {
    flex: 1;
  }
  @include desktop {
    max-width: 555px;
    padding-inline-start: 50px;
  }
}

.secondBlock {
  margin-top: 32px;
  @include tablet {
    flex: 1;
    margin-top: 48px;
  }
  @include desktop {
    margin-top: 0;
    margin-inline-end: 30px;
    max-width: 555px;
  }
}
