@import '@/styles/breakpoints.module';

.card {
  padding: 16px;
  box-shadow: 2px 4px 30px 0 var(--color-shadow-base);

  @include tablet {
    padding: 24px;
  }
  @include desktop {
    padding: 32px;
  }

  & > :first-child {
    margin-bottom: 12px;
    @include desktop {
      margin-bottom: 16px;
    }

    svg {
      width: 24px;
      height: auto;
      margin-inline-end: 8px !important;
      color: var(--palette-primary-700);

      @include tablet {
        width: 32px;
      }
    }
  }
}
