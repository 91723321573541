@import '@/styles/breakpoints.module';

.formSendingResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  margin-top: 12px;
  @include tablet {
    margin-top: 20px;
  }
  svg {
    max-width: 70px;
    width: 100%;
    height: auto;
    @include tablet {
      max-width: 100px;
    }
  }
}
