.heroBg {
  width: 100%;
  height: 1229px;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: -1;
  object-fit: cover;
  object-position: bottom;
  background: url(./img/blue-background.png),
    linear-gradient(180deg, rgb(6 9 23) 0%, rgb(5 29 123) 47%, rgb(0, 0, 0) 100%);
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    background: linear-gradient(180deg, transparent 0%, rgb(0, 0, 0) 100%);
    height: 30%;
    bottom: 0;
  }
}
