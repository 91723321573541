@import '@/styles/breakpoints.module';

.langPopupItem {
  padding-block-start: 12px;
  padding-block-end: 12px;
  padding-inline-start: 12px;
  padding-inline-end: 16px;

  display: flex;
  align-items: center;
  white-space: normal;

  transition: background-color 50ms ease-in-out;

  *,
  span {
    color: var(--palette-gray-900);
    font-weight: var(--palette-font-weight-regular);
  }

  &:hover:not(.langPopupSelectedLangItem) {
    background: var(--palette-primary-100);
  }
}

.langPopupSelectedLangItem {
  background-color: var(--palette-primary-700);
  *,
  span {
    color: var(--palette-white);
  }
}

.langPopupItemCheckContainer {
  min-width: 16px;
  min-height: 16px;
  margin-inline-end: 4px;
  & > * {
    width: 100%;
    height: auto;
  }
}
