@import '@/styles/breakpoints.module';

.table {
  [data-column-name='price-and-change'] {
    display: table-cell;
    @include tablet {
      display: none;
    }
  }

  [data-column-name='price'] {
    display: none;
    @include tablet {
      display: table-cell;
    }
  }

  [data-column-name='high24'] {
    display: none;
    @include tablet {
      display: table-cell;
    }
  }

  [data-column-name='low24'] {
    display: none;
    @include tablet {
      display: table-cell;
    }
  }

  [data-column-name='vol24'] {
    display: none;
    @include tablet {
      display: table-cell;
    }
  }

  [data-column-name='actions'] {
    display: none;
    @include desktop {
      display: table-cell;
    }
  }

  [data-column-name='trend'] {
    display: none;
    @include desktop {
      display: table-cell;
    }
  }
}
