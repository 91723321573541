@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.layoutCards {
  display: grid;
  gap: 16px;
  @include tablet {
    grid-template-columns: 1fr 1fr;
    gap: var(--cards-grid-gap);
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius-benefit-static-card);
  background-color: var(--color-benefit-static-card-bg);
  box-shadow: 2px 4px 60px 0 rgba(162, 164, 171, 0.12);
  overflow: hidden;
  width: 100%;

  @include desktop {
    max-width: 555px;
  }
}

.cardContent {
  z-index: 1;
  padding-top: 16px;
  padding-inline: 16px;

  @include tablet {
    padding-top: 24px;
  }
  @include desktop {
    padding-top: 60px;
    padding-inline: 60px;
  }
}

@mixin cardImage_ {
  margin-top: 8px;

  @include tablet {
    margin-top: 16px;
  }
  @include desktop {
    margin-top: 32px;
  }
}

.cardImage {
  @include cardImage_;
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.cardTitle {
  margin-bottom: 8px;
  @include tablet {
    margin-bottom: 16px;
  }
}

.cardDescription {
  color: var(--palette-gray-600);
  @include bodyM_;
}

.fillCardImage {
  @include cardImage_;
  z-index: 1;
  width: 100%;
}

.bgImage {
  object-fit: cover;
}
