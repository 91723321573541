@import '@/styles/breakpoints.module';

.layoutSection {
  display: grid;
  gap: 24px;
  @include tablet {
    gap: 52px;
  }
  @include desktop {
    gap: 120px;
  }
}
