@import '@/styles/breakpoints.module';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .wrapperText {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  img, svg {
    color: var(--palette-white);
    width: 100%;
    max-width: 48px;

    @include tablet {
      max-width: 64px;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 40px;

  @include tablet {
    grid-template-columns: repeat(5, 1fr);
    row-gap: 48px;
    margin-bottom: 20px;
  }

  @include desktop {
    row-gap: 60px;
    margin-bottom: 100px;
  }
}
