@import '@/styles/breakpoints.module';

.langPopup {
  width: calc(100vw - (16px * 2));

  @include tablet {
    width: auto;
  }

  box-shadow: 0px 0px 30px 0px rgba(11, 11, 11, 0.4);
  background-color: var(--palette-white);
  border-radius: var(--palette-border-radius-s);
}

.langPopupLangsList {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  max-height: 100%;
  overflow: auto;

  padding: 8px 0;
  width: 100%;

  @include desktop {
    min-width: max-content;
  }
}
