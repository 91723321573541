@import '@/styles/breakpoints.module';

.ctaWrapper {
  margin-top: var(--vstack-spacing-sm);
  margin-inline: auto;

  @include tablet {
    margin-top: var(--vstack-spacing-md);
    width: fit-content;
  }

  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }
}

.cta {
  width: 100%;
  @include tablet {
    width: auto;
  }
}
