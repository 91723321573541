@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.heroBody.heroBody {
  margin: 32px auto;
  @include tablet {
    margin: 48px auto;
  }
  @include desktop {
    margin: 80px auto;
  }
}

.heroFooterAwardsAndRatingContainer {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: center;
  grid-gap: 16px;
  justify-items: center;

  @include tablet {
    grid-template-columns: repeat(3, min-content);
    grid-column-gap: 60px;
  }

  .heroFooterRatingContainer {
    display: flex;
    flex-direction: column;
    grid-row: 2;
    grid-column: span 2;
    align-items: center;
    @include tablet {
      grid-row: auto;
      grid-column: auto;
      align-items: flex-start;
      text-align: start;
    }

    .heroFooterRatingDescription.heroFooterRatingDescription {
      color: var(--palette-gray-400);
      white-space: nowrap;
      @include typography.bodyS_;
      @include desktop {
        @include typography.subheadingS_;
      }
    }

    .heroFooterRating.heroFooterRating {
      margin-bottom: 5px;
    }
  }
}

.subCta.subCta {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  color: var(--color-text-primary);
  margin-top: 22px;

  img,
  svg {
    margin-inline-end: 12px;
  }

  @include tablet {
    text-align: center;
    margin-top: 18px;
  }

  & > *:first-child {
    margin-bottom: 10px;
    @include tablet {
      margin-bottom: 0;
    }
  }
}
