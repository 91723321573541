@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.tooltip.tooltip.tooltip.tooltip {
  z-index: 2001;
  margin: 0;
  padding: 16px;
  padding-top: 13px;

  :global .MuiTooltip-tooltip {
    max-width: none;
    background: none;
    padding: 0px;
    margin: 0px;
  }

  :global .MuiTooltip-arrow {
    width: 26px;
    height: 12px;
    bottom: calc(100% - 8px);

    &:before {
      content: none;
    }

    &:after {
      content: '';
      background: url('./arrow.svg') no-repeat center;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @include desktop {
    padding-top: 18px;
  }
}

.button {
  --color-btn-link-text: var(--color-text-primary);
  @include desktop {
    --color-btn-link-hover-text: var(--color-text-primary);
    --color-btn-link-active-text: var(--color-text-primary);
  }

  svg {
    transition: var(--nav-item-arrow-transition);
  }

  &.opened {
    svg {
      transform: var(--nav-item-arrow-transform);
    }
  }
}
