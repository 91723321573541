@use '@/styles/button.module';
@import '@/styles/breakpoints.module';

.heroSection {
  --padding-top-hero: 24px;
  padding-bottom: 24px;
  padding-top: calc(var(--height-header) + var(--padding-top-hero) + var(--height-app-banner));

  @include desktop {
    --padding-top-hero: 40px;
    padding-bottom: 40px;
  }

  &.withHeaderSecondNavRow {
    padding-top: calc(
      var(--height-header) + var(--height-second-nav-row) + var(--padding-top-hero) + var(--height-app-banner)
    );
  }
}

.subtitle {
  margin-top: 16px;
  @include tablet {
    margin-top: 24px;
  }
}

.ctaButton {
  width: 100%;

  @include tablet {
    width: auto;
  }

  @include button.btnM_;

  @include desktop {
    @include button.btnL_;
  }
}

.heroLayout {
  display: flex;
  flex-direction: column;

  .actionsSlot {
    margin-top: 24px;
    @include tablet {
      margin-top: 32px;
      text-align: center;
    }

    & > *:nth-child(2) {
      margin-top: 12px;
      @include desktop {
        margin-top: 16px;
      }
    }
  }

  .heroHeadingSlot {
    max-width: 946px;
    margin-inline: auto;
    @include tablet {
      text-align: center;
    }

    .subtitle {
      margin-inline: auto;
      max-width: 750px;
    }
  }

  .heroFooterSlot {
    margin-top: 36px;
  }
}

.heroLayoutRight {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    gap: var(--vstack-spacing-md);
  }

  .actionsSlot {
    margin-top: 24px;
    @include tablet {
      margin-top: 32px;
    }

    & > *:nth-child(2) {
      margin-top: 12px;
      @include desktop {
        margin-top: 16px;
      }
    }
  }

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .firstBlock {
      max-width: 653px;
      width: 100%;
    }

    .secondBlock {
      max-width: 457px;
      width: 100%;
    }

    .heroFooterSlot {
      margin-top: 36px;
    }
  }
}

.sideWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: relative;
  width: 100%;
  max-width: 457px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
