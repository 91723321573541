@import '@/styles/breakpoints.module';

.headerActions {
  .actionsMobmenu {
    display: none;
  }

  .pageScrolledDownActions {
    display: none;
  }

  .actionsDesktop {
    display: none;
    @include desktop {
      display: flex;
    }
  }

  .actionsMobile {
    display: flex;
    @include desktop {
      display: none;
    }
  }

  .actionsSecondLevelMobmenu {
    display: none;
  }

  &.mobmenuVisible {
    .actionsMobmenu {
      display: flex;
    }

    & > :not(.actionsMobmenu) {
      display: none;
    }
  }

  &.secondLevelMobmenu {
    .actionsSecondLevelMobmenu {
      display: flex;
    }

    & > :not(.actionsSecondLevelMobmenu) {
      display: none;
    }
  }

  &.pageScrolledDown {
    @include desktop {
      .pageScrolledDownActions {
        display: flex;
      }

      & > :not(.pageScrolledDownActions) {
        display: none;
      }
    }
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  @include large-desk {
    gap: 16px;
  }
}
