@import '@/styles/breakpoints.module';

.fixedContainer {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 1300;
}

.header {
  --space-btw-dropdown-and-header: 0px;
  --nav-item-height: 24px;

  height: var(--height-header);
  color: var(--color-header-text);

  @include desktop {
    height: var(--height-header);
  }

  &.bgVisible {
    background-color: var(--color-header-bg);
    backdrop-filter: blur(3px);
  }

  &.burgerOpened {
    background-color: var(--palette-white);
  }
}

.burgerDeepSectionTitle {
  padding: 8px 12px;
  font-weight: var(--palette-font-weight-semibold);
  color: var(--palette-gray-400);
  margin-bottom: 12px;
}

.headerInner {
  display: flex;
  align-items: center;
  height: 100%;
}

.headerMain {
  flex-grow: 1;
  display: flex;

  .menuItems {
    margin-inline-end: 10px;
  }
}
