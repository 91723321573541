.burgerMenuCollapseSection {
  .list {
    display: none;
  }

  &.opened {
    border-radius: 8px;
    background: var(--palette-gray-70);
    padding: 12px;

    .topLevelItemSummary {
      border-bottom: 1px solid var(--palette-gray-100);
      svg,
      img {
        transform: rotate(180deg);
      }
    }

    .list {
      display: block;
    }
  }
}

.topLevelItem {
  user-select: none;
  cursor: pointer;
  padding: 0 12px;
  color: var(--color-text-secondary);
  margin-bottom: 8px;

  .topLevelItemSummary {
    display: flex;
    padding: 8px 0;
    align-items: center;
    justify-content: space-between;
    font-weight: var(--palette-font-weight-semibold);
  }
}
