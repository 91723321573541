@import '@/styles/breakpoints.module';

.centerHeading {
  text-align: center;
  margin-inline: auto;
  max-width: 750px;
}

.spacing {
  & > * + * {
    margin-top: 12px;
    @include tablet {
      margin-top: 16px;
    }
  }

  & > *:nth-child(3) {
    margin-top: 24px;
    @include tablet {
      margin-top: 32px;
    }
  }
}
