.geometryBgDark {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 95%),
    radial-gradient(194.14% 91.43% at 2.43% 88.15%, rgba(10, 10, 10, 0.8) 0%, rgba(10, 10, 10, 0) 100%),
    conic-gradient(from 5deg at 92.78% 73.8%, rgba(65, 64, 62, 0.4) 0deg, rgba(37, 37, 35, 0.4) 360deg),
    conic-gradient(from -49deg at 85.69% 75.64%, rgba(98, 97, 97, 0.3) 0deg, rgba(37, 37, 37, 0.3) 360deg),
    #0a0a0a;
}

.geometryBgLight {
  background: conic-gradient(
      from 27deg at 8.2% 45.31%,
      rgba(239, 239, 239, 0.25) 0deg,
      rgba(255, 255, 255, 0.25) 360deg
    ),
    conic-gradient(
      from 172deg at 15.45% 34.5%,
      rgba(239, 239, 239, 0.4) 0deg,
      rgba(255, 255, 255, 0.4) 360deg
    ),
    conic-gradient(from 178deg at 88.78% 2.2%, rgba(235, 234, 234, 0.4) 0deg, rgba(255, 255, 255, 0.4) 360deg),
    conic-gradient(
      from 193deg at 88.75% 34.65%,
      rgba(219, 219, 219, 0.4) 0deg,
      rgba(255, 255, 255, 0.4) 360deg
    ),
    #fff;
}
