@import '@/styles/breakpoints.module';

.priceChartHeroBody {
  margin-top: 32px;

  @include tablet {
    margin-top: 40px;
  }

  @include desktop {
    margin-top: 60px;
  }
}

.disclaimer {
  margin-top: 12px;

  @include tablet {
    margin-top: 16px;
  }

  text-align: center;
}
