@import '@/styles/breakpoints.module';

.logo {
  flex-shrink: 0;
  --max-space-right: 24px;
  color: var(--color-text-primary);
  margin-inline-end: 12px;
  max-width: 109px;

  @include large-desk {
    margin-inline-end: 24px;
  }

  svg {
    width: 100%;
    height: auto;
    &[data-christmas] {
      margin-top: -5px;
    }
  }
}
