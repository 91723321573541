@import '@/styles/breakpoints.module';

.VStack {
  & > * + * {
    margin-top: var(--vstack-spacing-sm);

    @include tablet {
      margin-top: var(--vstack-spacing-md);
    }

    @include desktop {
      margin-top: var(--vstack-spacing-lg);
    }
  }
}
