@import '@/styles/breakpoints.module';

.slide {
  display: flex;
  align-items: center;
  transition: opacity 250ms ease-in-out;
}

.slide.navigation {
  &:not([class*='is-active']) {
    cursor: pointer;

    a {
      pointer-events: none;
    }
  }
}

.slide.slideOpacity {
  &:not(:global(.is-active)) {
    opacity: 0.4;
  }
}

.slide.dimNotVisible {
  &:not(:global(.is-prev)):not(:global(.is-active)):not(:global(.is-next)) {
    opacity: 0.4;
  }
}

.sliderContainer {
  width: 100%;
  padding: 0;
  max-width: 1440px;
  margin-inline: auto;
  overflow: hidden;
}

.trackWrapper {
  position: relative;
}

.trackWrapper.shadow,
.headerTrackWrapper.shadow {
  --gradient-direction: to right;
  --shadow-width: 10px;

  [dir='rtl'] & {
    --gradient-direction: to left;
  }

  &::before,
  &::after {
    background: linear-gradient(var(--gradient-direction), rgba(0, 0, 0, 1), rgba(10, 10, 10, 0));

    content: '';
    display: block;
    position: absolute;
    top: 0;
    line-height: 0;
    width: var(--shadow-width);
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    inset-inline-start: 0;
  }

  &::after {
    inset-inline-end: 0;
    transform: rotate(180deg);
  }

  @include tablet {
    --shadow-width: 90px;
  }
  @include desktop {
    --shadow-width: 190px;
  }
}

.mobile {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @include tablet {
    display: none;
  }
}

.tablet {
  margin-inline-start: 16px;
  display: none;
  @include tablet {
    display: flex;
  }
}

.grid {
  display: grid;
  gap: var(--vstack-spacing-sm);

  @include tablet {
    grid-template-columns: 1fr auto;
    row-gap: var(--vstack-spacing-md);
    column-gap: 16px;
  }

  @include desktop {
    gap: var(--vstack-spacing-lg);
  }
}

.header {
  order: 0;
}

.headerContent {
  @include tablet {
    max-width: 653px;
  }
}

.headerArrows {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .prevButton {
    margin-inline-end: 16px;
  }

  @include tablet {
    order: 1;
  }
}

.headerTrackWrapper {
  order: 1;

  @include tablet {
    order: 2;
    grid-column: span 2;
  }
}
