@import '@/styles/breakpoints.module';

.navItems {
  display: none;

  @include desktop {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-inline-end: 8px;

    @include large-desk {
      gap: 16px;
    }
  }
}

.pageScrolledDown {
  display: none;
}
