@import '@/styles/breakpoints.module';

.br {
  display: none;

  @include desktop {
    display: block;
  }
}

.sideWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @include tablet {
    margin-top: 48px;
  }
  @include desktop {
    margin-top: 0;
  }
}

.imgWrapper {
  position: relative;
  width: 100%;
  max-width: 555px;

  img {
    width: 100%;
    height: auto;
  }
}

.notification {
  position: absolute;
  right: 0;
  bottom: 28px;
  display: flex;
  padding: 8px 14px;
  min-width: 209px;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  line-height: 15px;
  color: var(--color-text-secondary);

  border-radius: 30px;
  background: #fff;
  backdrop-filter: blur(6px);

  @include tablet {
    bottom: 47px;
    padding: 12px 24px;
    border-radius: 50px;
    min-width: 338px;
    font-size: 16px;
    line-height: 24px;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: var(--palette-gray-900);
  @include tablet {
    font-size: 22px;
    line-height: 32px;
  }
}

.currency {
  font-size: 17px;
  font-weight: var(--palette-font-weight-bold);
  line-height: 22px;
  color: var(--palette-gray-900);
  margin-inline-start: 8px;

  @include tablet {
    font-size: 28px;
    line-height: 36px;
  }
}
