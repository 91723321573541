@import '@/styles/breakpoints.module';

.grid {
  display: grid;
  gap: 16px;

  @include tablet {
    grid-template-columns: repeat(var(--columns-md), 1fr);
    gap: var(--cards-grid-gap);
  }

  @include desktop {
    grid-template-columns: repeat(var(--columns-lg), 1fr);
  }
}

.tabletRow {
  @include tablet {
    display: flex;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }
}

.desktopRow {
  @include desktop {
    display: flex;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }
}
