@import '@/styles/breakpoints.module';

.actionSupport {
  --color-btn-link-text: var(--color-text-primary);
  --color-btn-link-hover-text: var(--color-text-primary);
  --color-btn-link-active-text: var(--color-text-primary);
}

.noEvents {
  pointer-events: none;
}


.arrowTooltip {
  color: var(--color-text-primary);
}

.tooltipContent{
  background: var(--color-card-bg);
  border-radius: var(--border-radius-sections-group);
  padding: 12px 16px;
}

.popperContent{
  color: var(--palette-gray-900);
}
