@import '@/styles/breakpoints.module';

.content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  .contentItem {
    max-width: 100%;
    width: 100%;
    border-radius: var(--palette-border-radius-m);
    background: var(--palette-gray-50);
    padding: 16px;
    flex: 1;

    .itemDescription {
      li, p {
        color: var(--palette-gray-900);
      }

      .text {
        border-radius: 8px;
        background: var(--palette-gray-100);
        padding: 12px;
        margin-bottom: 16px;

        @include tablet {
          margin-bottom: 24px;
        }
      }

      ol {

        li {
          position: relative;
          margin-bottom: 12px;
        }
      }

      ol {
        counter-reset: item;

        li {
          counter-increment: item;

          &:before {
            content: counter(item) '. ';
          }
        }
      }
    }

    .heading {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      @include tablet {
        margin-bottom: 24px;
      }

      img, svg {
        color: var(--color-btn-primary-bg);
        flex-shrink: 0;
      }

      .itemTitle {
        color: var(--palette-gray-900);
      }
    }

    @include tablet {
      padding: 32px 24px;
      max-width: 555px;
    }

    @include desktop {
      padding: 32px;
    }
  }

  @include tablet{
    flex-direction: row;
    gap: 12px;
  }
}

.sectionTitle {
  margin-bottom: 32px;
  text-align: center;
  @include tablet {
    margin-bottom: 48px;
  }
  @include desktop {
    margin-bottom: 60px;
  }
}
