@import '@/styles/breakpoints.module';

.disclaimer {
  text-align: center;

  margin-top: var(--vstack-spacing-sm);

  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }

  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }
}
