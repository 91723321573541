@import '@/styles/breakpoints.module';

.section {
  padding-block: var(--section-padding-sm);

  @include tablet {
    padding-block: var(--section-padding-md);
  }

  @include desktop {
    padding-block: var(--section-padding-lg);
  }
}

.sectionMargin {
  margin-block: var(--section-padding-sm);

  @include tablet {
    margin-block: var(--section-padding-md);
  }

  @include desktop {
    margin-block: var(--section-padding-lg);
  }
}
