@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.card {
  background-color: var(--color-card-bg);
  border-radius: var(--border-radius-conditions);
  padding: 16px;
  @include tablet {
    padding: 60px;
  }
  @include desktop {
    padding: 60px 24px;
  }
}

.cardBody {
  margin-inline: auto;
  @include desktop {
    max-width: 438px;
  }
}

.advantages {
  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 16px;
  }
}

.btnWrapper {
  margin-top: 24px;
  text-align: center;
  width: 100%;

  button{
    width: 100%;

    @include tablet{
      width: initial;
    }
  }


  @include tablet {
    margin-top: 40px;
    width: initial;
  }


  a {
    width: 100%;

    @include tablet {
      width: unset;
    }
  }
  span {
    display: block;
  }
}

.advantageWrapper {
  display: flex;
  height: fit-content;
  margin-bottom: 12px;
  @include tablet {
    margin-bottom: 0;
  }
}

.bulletWrapper {
  color: var(--palette-primary-700);
  height: fit-content;
  margin-inline-end: 12px;
}

.advantageDescription {
  font-weight: var(--palette-font-weight-medium);
}

.disclaimer {
  margin-top: 12px;
}

.note {
  margin-top: 24px;
}

.disclaimer, .note {
  color: var(--palette-gray-500) !important;
}
